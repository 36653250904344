<template>
  <v-navigation-drawer :value="isDrawerOpen" app floating width="260" class="app-navigation-menu" :right="$vuetify.rtl" @input="val => $emit('update:is-drawer-open', val)">
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <!--
        <v-img
          :src="require('@/assets/images/logos/logo.jpg')"
          max-height="50px"
          max-width="50px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        -->
        <v-slide-x-transition>
          <h2 class="app-title text--warning">
            MY Database
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link :title="$t('dashboard')" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>
      <nav-menu-link :title="$t('menu.staffInfo')" :to="{ name: 'staff' }" :icon="icons.mdiNaturePeople"></nav-menu-link>
      <nav-menu-group :title="$t('menu.legalCases')" :icon="icons.mdiDatabase">
        <nav-menu-link :title="$t('menu.oathCase')" :to="{ name: 'oath-cases' }" :icon="icons.mdiFileDocument"></nav-menu-link>
        <nav-menu-link :title="$t('menu.criminalCases')" :to="{ name: 'criminal-cases' }" :icon="icons.mdiFileDocument"></nav-menu-link>
        <nav-menu-link :title="$t('menu.civilCases')" :to="{ name: 'civil-cases' }" :icon="icons.mdiFileDocument"></nav-menu-link>
        <nav-menu-link title="រឿងក្តីរដ្ឋប្បវេណីដែលមិនជាបណ្តឹង" to="" :icon="icons.mdiFileDocument"></nav-menu-link>
        <nav-menu-link :title="$t('menu.defendantCases')" :to="{ name: 'defendant-cases' }" :icon="icons.mdiFileDocument"></nav-menu-link>
        <nav-menu-link title="រឿងក្តីអនុវត្តដោយបង្ខំ" to="" :icon="icons.mdiFileDocument"></nav-menu-link>
      </nav-menu-group>

      <nav-menu-link :title="$t('menu.accountSettings')" :to="{ name: 'pages-account-settings' }" :icon="icons.mdiAccountCogOutline"></nav-menu-link>
      <!-- Admin Menu -->
      <nav-menu-group :title="$t('menu.admin')" :icon="icons.mdiSecurity">
        <nav-menu-link :title="$t('caption.userManagement')" :to="{ name: 'admin-users' }"></nav-menu-link>
        <nav-menu-link :title="$t('menu.systemVariable')" :to="{ name: 'system-setting-dashboard' }"></nav-menu-link>
      </nav-menu-group>
      <!-- End Admin Menu-->
      <nav-menu-group :title="$t('menu.report')" :icon="icons.mdiChartPie">
        <nav-menu-link title="របាយការណ៍បុគ្គលិក" :to="{ name: 'custom-report' }"></nav-menu-link>
        <nav-menu-link title="របាយការណ៍សំណុំរឿង" :to="{ name: 'case-report' }"></nav-menu-link>
      </nav-menu-group>
      <!--
      <nav-menu-group
        title="Pages"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group>
      -->
      <!--   <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiSecurity,
  mdiFileDocumentEdit,
  mdiInformation,
  mdiChartPie,
  mdiNaturePeople,
  mdiFileDocument,
  mdiDatabase
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null
    }
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiSecurity,
        mdiFileDocumentEdit,
        mdiInformation,
        mdiChartPie,
        mdiNaturePeople,
        mdiFileDocument,
        mdiDatabase
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
